<div
  class="payment-wrapper"
  [class.advanced-event]="event?.type === EventType.ADVANCED_ONE_COLUMN"
  [class.book-a-slot-wrapper]="isBookASlot"
>
  <div
    class="payment-body"
    [class.generic]="isGeneric"
    [class.book-a-slot]="isBookASlot"
  >
    <div class="customer-info">
      @if (
        !bookEventSlotStep ||
        bookEventSlotStep === BookEventSlotStep.PERSONAL_DATA
      ) {
        <app-personal-data
          [form]="form"
          [loggedUser]="loggedUser"
          [appearance]="getAppearance()"
          [showLoggedUserData]="isGeneric && this.loggedUser"
          [isBookASlot]="isBookASlot"
        />
        @if (isGeneric) {
          <!-- <mat-accordion class="wrapper-expansion partly-clickable" multi>
          <mat-expansion-panel
            [expanded]="setBillingAddressOpenState()"
            (opened)="setBillingAddressOpenState.set(true)"
            (closed)="setBillingAddressOpenState.set(false)"
            (click)="resetBillingAddress()"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                ><span class="panel-title bold">
                  @if (!setBillingAddressOpenState()) {
                    {{ "APP.VOUCHERS.ADD_BILLING_ADDRESS" | translate }}
                  } @else {
                    {{ "APP.VOUCHERS.HIDE_BILLING_ADDRESS" | translate }}
                  }
                </span></mat-panel-title
              >
            </mat-expansion-panel-header>
            <app-tax-invoice-details
              [form]="billingAddressForm"
              [appearance]="getAppearance()"
              [showTitle]="false"
              [isGeneric]="true"
              class="w-100"
            />
          </mat-expansion-panel>
        </mat-accordion> -->
          @if (additionalDataForm && chosenSlotTicketDataFields) {
            <div class="additional-data-cont">
              @for (
                dataField of chosenSlotTicketDataFields;
                track dataField.id
              ) {
                <app-event-rsvp-question
                  [form]="additionalDataForm"
                  [question]="dataField"
                  matFormFieldClass="w-100"
                  class="payment-data-field"
                ></app-event-rsvp-question>
              }
            </div>
          }
          <div class="generic-address-cont">
            <div class="panel-title bold">
              {{ "APP.VOUCHERS.BILLING_ADDRESS" | translate }}
            </div>
            <app-tax-invoice-details
              [form]="billingAddressForm"
              [appearance]="getAppearance()"
              [showTitle]="false"
              [isGeneric]="true"
              class="w-100"
            />
          </div>
        } @else {
          <app-tax-invoice-details
            [form]="billingAddressForm"
            [appearance]="getAppearance()"
            class="w-100"
          />
        }
      }
      @if (
        !bookEventSlotStep || bookEventSlotStep === BookEventSlotStep.PAYMENT
      ) {
        @if (stripe && elementsOptions && canPay) {
          @if (ticketOrder && ticketOrder.totalPrice > 0) {
            @if (elementsOptions.clientSecret) {
              <div class="pay-with-cont">
                @if (isBookASlot) {
                  <div class="panel-title mb-1">
                    {{ "APP.EVENT_PAYMENT.CHOOSE_PAYMENT_METHOD" | translate }}
                  </div>
                } @else {
                  <div class="title-wrapper">
                    <div class="title">
                      {{ "APP.EVENT_PAYMENT.PAY_WITH" | translate }}
                    </div>
                  </div>
                }
                <ngx-stripe-elements
                  [stripe]="stripe"
                  [elementsOptions]="elementsOptions"
                  class="stripe-elements"
                >
                  @if (isGeneric && !isBookASlot) {
                    <div class="stripe-card-element">
                      <ngx-stripe-card
                        [options]="cardOptions"
                        (change)="stripeElementsChange($event)"
                      />
                    </div>
                  } @else {
                    <ngx-stripe-payment
                      [options]="paymentElementOptions"
                      (change)="stripeElementsChange($event)"
                    />
                  }
                </ngx-stripe-elements>
              </div>
              @if (isGeneric && !isBookASlot) {
                <button
                  class="btn btn-primary order-btn"
                  (click)="pay()"
                  [class.spinner]="paying()"
                  [disabled]="
                    !isStripeElementValid || !acceptTermsAndConditions
                  "
                >
                  {{ "APP.EVENT_PAYMENT.ORDER" | translate }}
                </button>
                <div class="accept-terms">
                  <mat-checkbox
                    (change)="toggleTermsAndConditions()"
                  ></mat-checkbox>
                  {{ "APP.I_AGREE_TO" | translate }}
                  <a
                    href="https://eventpage.ai/terms-of-use/"
                    target="_blank"
                    >{{ "APP.TERMS_AND_CONDITIONS" | translate }}</a
                  >
                </div>
              }
            }
          } @else {
            @if (isGeneric && !isBookASlot) {
              <button
                class="btn btn-primary order-btn"
                (click)="pay()"
                [class.spinner]="paying()"
                [disabled]="!acceptTermsAndConditions"
              >
                {{ "APP.REGISTER" | translate }}
              </button>
              <div class="accept-terms">
                <mat-checkbox
                  (change)="toggleTermsAndConditions()"
                ></mat-checkbox>
                {{ "APP.I_AGREE_TO" | translate }}
                <a href="https://eventpage.ai/terms-of-use/" target="_blank">{{
                  "APP.TERMS_AND_CONDITIONS" | translate
                }}</a>
              </div>
            }
          }

          @if (isBookASlot) {
            <div class="book-a-slot-terms">
              <div class="accept-terms">
                <mat-checkbox
                  (change)="toggleTermsAndConditions()"
                ></mat-checkbox>
                {{ "APP.I_AGREE_TO" | translate }}
                <a [href]="termsAndConditionsLink" target="_blank"
                  >{{ "APP.THE_TERMS_AND_CONDITIONS" | translate }}.</a
                >*
              </div>
              <div class="accept-terms">
                <mat-checkbox (change)="toggleDataPrivacy()"></mat-checkbox>
                {{ "APP.I_AGREE_TO" | translate }}
                <a [href]="dataPrivacyLink" target="_blank"
                  >{{ "APP.THE_PRIVACY_POLICY" | translate }}.</a
                >*
              </div>
            </div>
          }
        }
      }
    </div>

    @if (!isGeneric) {
      <app-order-overview
        [voucher]="voucher"
        [ticketOrder]="ticketOrder"
        [mainImage]="mainImage"
        [squaredStyle]="event?.type === EventType.ADVANCED_ONE_COLUMN"
        [isValid]="
          isStripeElementValid && form.valid && !!billingAddressForm?.valid
        "
        [paying]="paying"
        [event]="event"
        (pay)="pay()"
      />
    }
  </div>
</div>
